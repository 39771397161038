import React from 'react';
import Default from '../../../layouts/default';
import useAlgoliaSearchAPI from '../../../hooks/useAlgoliaSearchAPI';
import algoliaIndices from '../../../../algolia-indices';
import EmailSubscribeBanner from '../../../components/EmailSubscribeBanner';
import useBlogHeader from '../../../hooks/useBlogHeader';
import { graphql } from 'gatsby';
import { ContentfulPageBlogConnection } from 'GatsbyTypes';
import BlogWrapper from '../../../components/Blog/Search';
import { BlogPostSection } from '../../../components/Blog/BlogPostSection';

interface PageData {
  data: {
    blog: ContentfulPageBlogConnection;
  };
  params: {
    id: string;
  };
}

const BlogSearch: React.FC<PageData> = ({ data: { blog }, params: { id } }) => {
  const {
    data: categoryPosts,
    incrementPage: incrementCategoryPage,
    hasMoreToShow: hasMoreToShowCategoryPosts,
    totalFound: totalPostsFound,
  } = useAlgoliaSearchAPI(algoliaIndices.blogFullSearch, id);

  const { BlogHeader, featuredBlog, subFeaturedBlogs } = useBlogHeader(blog);

  const header = (
    <h4 className="py-2">
      {totalPostsFound} Results for <strong className="text-primary">{id}</strong>
    </h4>
  );
  const hasBlogPosts = totalPostsFound > 0;
  const featuredHeader = <div className="pb-5 font-weight-bold">But here are some featured posts</div>;

  return (
    <Default>
      <BlogHeader />
      <main className="container">
        <BlogWrapper query={id}>
          <BlogPostSection
            header={header}
            edges={categoryPosts}
            isLoadMoreButtonVisible={hasMoreToShowCategoryPosts}
            onLoadMore={incrementCategoryPage}
          />
          {!hasBlogPosts ? (
            <BlogPostSection
              header={featuredHeader}
              edges={[featuredBlog, ...subFeaturedBlogs]}
              isLoadMoreButtonVisible={hasMoreToShowCategoryPosts}
              onLoadMore={incrementCategoryPage}
            />
          ) : (
            <></>
          )}
        </BlogWrapper>
      </main>
      <section>
        <EmailSubscribeBanner />
      </section>
    </Default>
  );
};

export default BlogSearch;

export const query = graphql`
  {
    blog: allContentfulPageBlog(limit: 1) {
      edges {
        node {
          seo {
            ...ContentfulSeo
          }
          ...blogFields
        }
      }
    }
  }
`;
